
            @import "@/assets/sass/vars.portal.scss";
          










































input.input-dep {
    border-radius: 6px;
    border: 1px solid $gray-600;
    font-size: 12px;
    width: 60px;
    text-align: center;

    &.disabled {
        background: $gray-200 !important;
        cursor: not-allowed;
    }

    &.has-error {
        border: 2px solid red !important;
    }
}
