
            @import "@/assets/sass/vars.portal.scss";
          





























































































































































































































































































































































.separator-timeline {
    border: 1px dashed $gray-500;
    margin-bottom: 30px;
    margin-top: 30px;
}

.g-r-c {
    display: flex;
    &__ctrl {
        flex: 0 0 350px;
    }

    &__graph {
        flex: 1 1;
        overflow: auto;
    }
}
