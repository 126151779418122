
            @import "@/assets/sass/vars.portal.scss";
          

































































































































































































































































































































































































































































































































.gantt-chart-container {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    user-select: none;
    cursor: grab;
}

.gantt-chart-container:active {
    cursor: grabbing;
}

.gantt-chart {
    position: relative;
}

.gantt-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* Header */
.gantt-header {
    position: absolute;
    top: 0;
    height: 40px; /* headerHeight */
    border: 1px solid green;
}

.gantt-header-row {
    position: relative;
    height: 20px;
}

.gantt-cell {
    position: absolute;
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    text-align: center;
    font-size: 10px;
    background-color: $white;
    height: 20px;
    box-sizing: border-box;
}

// .gantt-cell {
//     position: absolute;
//     top: 0;
//     height: 40px; /* headerHeight */
//     border-left: 1px dotted $gray-600;
//     padding: 5px;
//     text-align: center;
//     font-size: 12px;

//     &__hdr_w {
//         font-size: 11px;
//         font-weight: 600;
//     }

//     &__hdr_y {
//         font-size: 10px;
//     }
// }

/* Milestone names */
.milestone-names {
    position: absolute;
    top: 0;
    left: 0;
}

.milestone-name {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
    line-height: 30px; /* Align text vertically */
    margin-top: 10px;
    border-top: 1px dotted $gray-600;
}

.gantt-bars {
    position: absolute;
    border-top: 1px dotted $gray-600;
    width: 100%;
    height: 100%;
}

.gantt-bar-c {
    border-bottom: 1px dotted $gray-600;
    width: 100%;
}

.gantt-bar {
    position: absolute;
    height: 22px;
    border-radius: 3px;

    &.has-baseline {
        height: 10px;
    }

    &__main {
        background-color: $default;
        opacity: 0.6;
    }

    &__baseline {
        background-color: $gray-300;
    }
}
