
            @import "@/assets/sass/vars.portal.scss";
          













































































































































































































































































































.v-picker-week-range-picker {
    position: relative;
    display: inline-block;

    input {
        border-radius: 6px;
        border: 1px solid $gray-600;
        font-size: 12px;
        width: 60px;
        text-align: center;
        cursor: pointer;
    }

    .year-switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray-300;
        padding-top: 10px;

        button {
            margin-right: 0px !important;
        }

        &__yr {
            font-size: 18px;
            font-weight: 600;

            width: 90px;
            text-align: center;
        }
    }

    .week-buttons {
        border-top: 1px solid $gray-300;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-top: 10px;
    }

    .weeks-grid {
        padding-left: 5px;
        display: flex;
        flex-wrap: wrap;

        width: 320px;
        overflow-y: auto;
    }

    .week {
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        font-weight: bold;
        border: 1px solid $gray-500;
        border-radius: 6px;
        cursor: pointer;
        width: 34px;
        padding: 2px;
        color: $primary;

        &__dt {
            font-weight: normal;
            color: $gray-600;
            margin-top: -5px;
            font-size: 9px;
        }
    }

    .picker-popup {
        position: absolute;
        border: 1px solid #ccc;
        background: white;
        z-index: 1000;
        top: 0;
        left: 0;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.8);
        padding: 10px;
        border-radius: 4px;
    }

    .week:hover {
        background: #e0e0e0;
    }

    .week.selected {
        background: $primary;
        color: white;
        font-weight: bold;

        .week__dt {
            color: white;
        }
    }

    .week.dependent-date {
        border: 3px dashed $primary;
    }

    .dependent__demo {
        .week {
            font-size: 10px;
            margin-bottom: 0px;
        }
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-left: 5px;
        font-size: 10px;
    }
}
