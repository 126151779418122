
            @import "@/assets/sass/vars.portal.scss";
          

































































































































































































































































































































































































































.style-baseline {
    background: $gray-300;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    font-size: 13px;
    margin-left: 8px;
}

.style-master {
    background: $default;
    opacity: 0.6;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 13px;
}

.cont {
    border-right: 5px solid $gray-600;

    .editor {
        font-size: 12px;
        border-bottom: 1px dotted $gray-600;
        padding: 5px;
        line-height: 20px; /* Align text vertically */
        display: flex;

        &.is-clicked {
            background: $gray-300;
        }

        &__name {
            height: 24px;
            position: relative;
        }

        &__iname {
            position: absolute;
            background: white;
            padding-left: 15px;
            z-index: 9999;
            width: 500px;
            font-weight: bold;
            padding-top: 2px;
            padding-bottom: 2px;
        }

        &__id {
            font-weight: 600;
            flex: 0 0 40px;
            text-align: center;
        }

        &__inp {
            display: flex;
            justify-content: space-evenly;
            flex: 1 1;
            flex-direction: row;
        }

        &__dep {
            text-align: center;
            flex: 0 0 105px;
            input[type="text"] {
                width: 46px;
            }
        }
    }
}
